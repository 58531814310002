@import 'variables';

* {
  vertical-align: top;
}

table {
  width: 100%;
}

td {
  vertical-align: middle;

  span {
    line-height: 40px;
  }
}

html, body {
  min-height: 100%;
  min-width: 640px;
  height: auto !important;
  width: 100%;
  margin: 0;
  padding: 0;
  font-weight: 300;
}

body {
  background: linear-gradient(
                  90deg,
                  $navi-bg,
                  $navi-bg $navbar-width,
                  $main-bg $navbar-width,
                  $main-bg
  ) !important;
}

#main {
  min-height: calc(100% - #{$navbar-width});
}

/*
 * NAVIGATION
 */

#header {
  background: $brand-primary;
  box-shadow: 0 4px 4px -4px #0c0c0c;
  margin: 0;
  width: 100%;
  height: $header-height;
  position: relative;
  display: inline-block;

  ul {
    list-style-type: none;
  }

  li {
    display: inline;
    margin: 0 25px;
    font-weight: 300;
  }

  a {
    color: $text-color;

    &.no-href {
      cursor: pointer;
    }
  }

  .header-content {
    position: absolute;
    top: 0;
    left: $navbar-width;
    right: 0;
    height: $header-height;
    background: $highlight-color;
    padding: 40px 50px 0 40px;
    line-height: 40px;

    .title {
      display: inline-block;
      font-size: 20px;
      vertical-align: center;
    }

    #switch {
      /*margin-left: 10px; deprecated since bootstrap does the magic now */
      font-weight: 300;
      display: inline-block;
      padding: 5px 0;
      font-size: 16px;
      text-align: center;
    }
  }

  .logo-container {
    width: $navbar-width;
    line-height: 85px;
    vertical-align: middle;
    text-align: center;
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    padding-top: 35px;
  }
}

#avatar, .burger-menu {
  display: inline-block;
  width: 50px;
  height: 50px;
  /*border-radius: 25px;*/
  overflow: hidden;
}

#avatar {
  background: url(/img/icons/logout.svg) no-repeat center center;
  background-size: 30px 30px;
}

.burger-menu {
  background: url(/img/icons/menu.svg) no-repeat center center;
  background-size: 30px 30px;
  float: left;
}

#navigation {
  z-index: 1000;
  background: $navbar-default-bg;
  position: relative;
  display: inline-block;
  width: $navbar-width;

  ul {
    padding-left: 0;
  }

  li {
    border-bottom: 1px solid $navbar-border-color;

    a {
      position: relative;

      &.active, &:hover {
        color: $navbar-active-link;
        background: $navbar-selected-bg;
      }

      &:focus {
        color: inherit;
      }

      &.dropdown-toggle {
        &:after {
          content: "";
          height: 20px;
          width: 20px;
          position: absolute;
          right: 20px;
          bottom: 20px;
          z-index: 0;
          background: url('/img/icons/arrow_ol.svg') no-repeat center center;
          background-size: 16px;
          transform: rotate(0deg);

          transition-property: transform;
          transition-duration: 0.3s;
          -webkit-transition-property: transform;
          -webkit-transition-duration: 0.3s;
        }

        &[aria-expanded='false'],
        &:not([aria-expanded]),
        &.collapsed {
          &:after {
            transform: rotate(-90deg);

            -webkit-transition-property: transform;
            -webkit-transition-duration: 0.3s;
            transition-property: transform;
            transition-duration: 0.3s;
          }
        }
      }
    }
  }

  ul, li {
    width: 100%;
    color: $navbar-text-color;
    list-style-type: none;
  }

  a {
    text-decoration: none;
    width: $navbar-width;
    display: block;
  }

  > ul > li {
    /* first level links */

    &> a {
      line-height: 40px;
      background: $navbar-default-bg;
      text-transform: uppercase;
      font-weight: normal;
      padding: 10px 40px;
    }

    &> ul > li {
      /* second level links */

      &> a {
        line-height: 50px;
        padding-left: 84px;
        background: $navbar-selected-bg;
        display: block;

        &.dropdown-toggle:after {
          background-size: 14px;
          bottom: 12px;
        }
      }

      &:first-child {
        border-top: 1px solid $navbar-border-color;
      }

      &:last-child {
        border:none;
      }

      &> ul > li {
        /* third level links */

        &> a, a:focus {
          padding-left: 84px;
          line-height: 50px;
          background: #bebebe !important;
          color: $text-color !important;

          &.active, &:hover {
            color: $navbar-text-color !important;
          }
        }
      }
    }
  }

  a.logout {
    color: $text-color;
    background: $brand-primary;

    &:hover {
      color: $text-color;
      background: $navbar-active-link;
    }
  }
}

#logo {
  font-family: Jura, monospace;
  font-size: 30px;
  letter-spacing: 2px;
  line-height: 30px;
  vertical-align: top;

  display: inline-block;
  overflow: visible;
  background: url('/img/logo.svg') no-repeat left center;
  background-size: 50px 50px;

  padding: 20px 0 0 50px;

  margin: auto auto auto -50px;
}

#logo,#logo:hover {
  text-decoration: none;
  color: $text-color;
}

/*
 * GENERAL
 */

.right {
  float: right;
  display: inline-block;
}

.left {
  float: left;
  display: inline-block;
}

.clear-float {
  float: none;
}

.color-primary {
  color: $brand-primary;
}

$aligns: 'middle', 'top', 'bottom';
@each $align in $aligns {
  .vertical-#{$align} {
    vertical-align: #{$align};
  }
}

.app-settings {

  .panel-body {

    min-height: 280px;

    .row {
      line-height: 3.0;
    }
  }
}

.vertical-dots {
  position: relative;
  line-height: 30px;
  overflow: visible;

  > a {
    display: inline-block;
    width: 1em;
    height: 30px;
    background: url('/img/icons/dots_v_w.svg') no-repeat center center;
    background-size: auto 21px;
  }

  &.open > a,
  > a:hover {
    background-image: url('/img/icons/dots_v.svg');
  }

  ul {
    position: absolute;
    top: 30px;
    right: 0;
    left: auto;
    min-width: 0;

    > li a {
      color: grey;
      padding-left: 10px;
      padding-right: 10px;
      font-weight: 300;
      min-width: 90px;

      &:hover {
        color: $navbar-active-link;
      }
    }
  }

}

.content-spacing-sm,
.content-spacing-md,
.content-spacing-lg {
  display: block;
  width: 100%;
}

.content-spacing-sm {
  height: 1em;
}

.content-spacing-md {
  height: 2em;
}

.content-spacing-lg {
  height: 3em;
}

label.icon-label {
  width: 30px;
  height: 30px;
  text-align: center;

  img {
    width: 28px;
    height: auto;
  }
}

.login-title h1 {
  width: 500px !important;
  margin-left: auto !important;
  margin-right: auto !important;
  font-weight: 500 !important;
  text-shadow: 2px 2px 2px grey;
}

/*
 * PANELS
 */

.panel-default {
  border: none !important;

  .panel-body {
    color: $panel-text-color;
    line-height: 40px;
    padding: 0 20px;
  }

  .error-box {
    font-weight: bold;
    letter-spacing: 1px;
    display: block;
    color: inherit;
    line-height: 1.5;
  }

  h1,h2,h3,h4,h5 {
    text-shadow: 2px 2px 2px grey;
  ;
  }
}

.panel {

  &.login-panel {
    width: 515px;
    margin-left: auto;
    margin-right: auto;

    .panel-heading {
      ul {
        margin-top: 30px!important;
        margin-right: 10px!important;
        float: right !important;

        li {
          padding: 0 10px;
        }
      }

      a {
        line-height: 20px!important;
        font-size: 16px !important;

        &.active {
          font-weight: bold!important;
        }
      }

      h1,h2,h3,h4 {
        line-height: 40px!important;
        margin-top: 15px!important;
        font-weight: 500!important;
      }
    }

    .panel-body input[type="submit"] {
      line-height: 30px!important;
    }
  }

  th {
    &.sorting,
    &.sorting_asc,
    &.sorting_desc {
      background: no-repeat right 0.5em center;
      background-size: 0.5em auto;
      padding-right: 1em;
    }

    &.sorting {
      background-image: url('/img/icons/arrows_sm_w_ns.svg');
    }

    &.sorting_asc {
      background-image: url('/img/icons/arrow_sm_w_up.svg');
    }

    &.sorting_desc {
      background-image: url('/img/icons/arrow_sm_w_down.svg');
    }
  }

  &.panel-default {
    background-color: $panel-default-bg;

    input, textarea {
      background: none;
      border: none;
      font-size: 14px;
      color: black;
      line-height: 20px;
    }

    input, button {
      &[type='submit'],
      &[type='button'] {
        margin: 30px auto 20px auto;
        display: block;
        width: 200px;
        line-height: 50px;
        border-radius: 3px;
        background: $brand-primary;
        color: $text-color;
      }
    }

    select {
      border-radius: 5px;
      border: 1px solid $brand-primary;
      background: $dropdown-bg;
      color: $dropdown-link-color;

      option[selected] {
        background: $dropdown-active-link-bg;
        color: $dropdown-active-link-color;
      }

      &[multiselect] {
        overflow: hidden;
      }
    }

    >.panel-footer {
      line-height: 15px!important;
      font-size: 12px;
      border-radius: 0;
    }

    >.panel-heading,>.panel-footer {
      color: $text-color;
      min-height: 80px;
      line-height: 60px;
      background: $brand-primary;
      vertical-align: middle;

      h1,h2,h3,h4,h5 {
        margin: 0;
        display: inline-block;
      }

      ul.nav-pills {
        display: inline-block;
        /*float: right;*/
      }

      .nav-pills > li > a {
        color: $text-color;
        font-weight: 300;
        padding: 0 0 0 10px;
        line-height: 40px;
        font-size: 20px;
      }

      .nav-pills > li >a {
        background: transparent;
      }

      .nav-pills > li.active > a {
        font-weight: 600;
      }
    }
  }

  &.panel-dark-hr .panel-heading {
    border-bottom: 1px solid $navbar-default-bg;
  }

  &.panel-dark,&.panel-dark-hr {
    background: $highlight-color;
    box-shadow: 4px 4px 4px -2px #0c0c0c;
    border: none;
    padding: 20px;

    .panel-heading {
      padding-top: 0;
      padding-left: 0;
    }

    .panel-body {
      padding: 0;
    }

    .panel-heading ~ .panel-body {
      padding-top: 15px;
      padding-bottom: 15px;
    }

    table {
      tr {
        border-bottom: 1px solid $navbar-default-bg;

      }

      tbody tr:hover {
        background-color: rgba(0, 0, 0, 0.2);
      }

      th {
        font-weight: normal;
      }

      td,th {
        border-right: 1px solid $navbar-default-bg;
        padding: 5px 10px;

        a {
          color: $text-color;
          text-decoration: none;

          &:hover {
            color: $brand-primary;
          }
        }

        .dropdown-menu {
          background-color: $dropdown-bg;
        }

        .dropdown a {
          color: $dropdown-link-color;

          &:hover {
            color: $dropdown-active-link-color;
            background-color: $dropdown-active-link-bg;
          }
        }
      }

      td:last-child,th:last-child {
        border-right: none;
      }

      &.single-table {
        thead > tr {
          border-bottom-width: 3px;
        }
        th, td {
          padding: 1em;
        }
      }
    }

    .panel-heading, .panel-footer {
      background-color: inherit;

      .title {
        text-transform: uppercase;
      }

      .filter-btn {
        border-radius: 10px;
        line-height: 20px;
        color: $body-bg;
        padding-right: 40px;
        padding-left: 10px;
        background: url('/img/icons/arrow_top.svg') no-repeat right 10px center $navbar-default-bg;
        background-size: auto 20px;
        float: right;

        .filter-btn-label {
          color: grey;
        }
      }
    }

    .panel-body {
      background-color: inherit;
    }

    .diag-pills a {
      color: $text-color;
      opacity: 0.5;

      &:hover, &.active {
        opacity: 1.0;
      }
    }

    .form-row > div[class^=col-] {
      padding: 0;
    }

    .form-row.selector-row {
      > div[class^=col-], span#refresh-button {
        line-height: 46px!important;
      }
    }

    label {
      font-weight: normal;
    }

    input, textarea {
      font-weight: 300;
      background: transparent;
      border-radius: 5px;
      border: none;
      padding-left: 0.5em;
      padding-right: 0.5em;

      &:not([type='checkbox']) {
        width: 100%;
      }

      &[type='submit'],
      &[type='submit'].active {
        &:not([disabled]) {
          background: $text-color;
          color: $brand-primary;
          height: 30px;
        }
      }

      &[type='button'].cancel,
      &[type='button'].cancel.active {
        background-color: $brand-primary;
        color: $text-color;
        height: 30px;
      }

      &:not([disabled]).active {
        background: rgba(0, 0, 0, 0.2);
      }
    }

    select,
    select.active {
      width: 100%;
      min-height: 30px;
      color: $text-color;
      font-weight: 500;

      background-color: rgba(0, 0, 0, 0.2);

      border-radius: 5px;
      border: none;

      padding: 0 2.2em 0 3px;
      margin: 0 4px;

      -webkit-appearance: none;
      -moz-appearance: none;

      &:not([multiple]) {
        background: url('/img/icons/arrow_sm_w_down.svg') no-repeat right 1em center rgba(0, 0, 0, 0.2);
        background-size: 0.8em auto;

        optgroup, option {
          color: black;
          font-family: 'Raleway', sans-serif;
        }

        optgroup {
          background-color: #bebebe;
        }

        option {
          background-color: white;
          min-height: 24px;
          vertical-align: middle;
        }
      }
    }

    select::-ms-expand {
      display: none;
    }

    input.submit-invert {
      background-color: $brand-primary!important;
      color: white!important;
      font-weight: 500;
    }

    .form-row label {
      margin-bottom: 0;
    }

    .nav {
      &.nav-pills {
        li a {
          padding: 0;
          background-color: transparent;
          font-weight: 300;
          font-size: 80%;
          margin-right: 2em;
          color: $text-color;

          &:hover {
            color: $brand-primary;
          }
        }

        li.active a {
          font-weight: bold;
        }
      }
    }
  }

  .diag-pills {
    a {
      margin: 0 10px;
      text-decoration: none;
      font-weight: 300;

      &:hover {
        color: inherit;
      }
    }

    a:first-child {
      margin-left: 0;
    }

    a:last-child {
      margin-right: 0;
    };
  }
}

.panel, .panel-heading, .panel-default {
  border-radius: 0 !important;
}

.icon {
  background: no-repeat center left;
  background-size: auto 1.5em;
  width: 40px;
  height: 40px;
  display: inline-block;
  vertical-align: middle;
  overflow: visible;

  &.live {
    width: 60px;
  }

  $icons: 'dashboard', 'users', 'logout', 'setting', 'clock_g', 'clock_w', 'app', 'live';
  @each $i in $icons {
    &.#{$i} {
      background-image: url("/img/icons/#{$i}.svg");
    }
  }

  &.phone {
    background-image: url("/img/icons/phone_temp.png");
  }
}

/*
 * PAGE
 */
.mono {
  font-family: monospace;
}

a {
  h1, h2, h3, h4, h5, h6 {
    color: $text-color;
    text-decoration: none;
  }
}

a .caret-extended {
  display: inline-block;
  float: right;
  width: 1em;
  height: 1em;
  background: url('/img/icons/arrow_sm_w_right.svg') center center no-repeat;
  background-size: 0.5em 0.5em;
  transition: transform 0.5s;
  transform: rotate(90deg);
}

a.collapsed .caret-extended {
  transform: none!important;
  transition: transform 0.5s;
}

@media (min-width: 768px) {
  #main {
    width: calc(100% - 320px);
  }
}

#main {
  margin: 0;
  padding: 30px;
  display: inline-block;
  position: relative;
  background-color: $main-bg;

  .panel-dark,.panel-dark-hr {
    h1,h2,h3,h4,h5 {
      font-weight: 500;
    }
  }

  h1, h2, h3, h4, h5, h6 {
    text-transform: uppercase;

    &.table-heading {
      margin-bottom: 0.5em;
    }
  }

  h1, h2, h3, h4, h5 {
    font-weight: 300;
  }

  h6 {
    font-weight: 500;
  }

  h1 {
    margin-bottom: 40px;
  }

  .row.form-row {
    margin: 0;
  }

  .panel .form-row {
    border-bottom: 1px solid $text-color;
    padding: 20px 20px 10px 20px;
    line-height: 30px;

    input {
      line-height: inherit;
      width: 100%;
      padding-left: 5px;
      padding-right: 5px;
    }
  }

  .panel .panel-footer {
    border-top: none;
  }

  .map-toggle-link {
    color: $text-color;
    text-decoration: none;
    padding-right: 5px;

    &::after {
      content: '';
      display: inline-block;
      width: 2em;
      height: 1em;
      background: url('/img/icons/arrow_ol.svg') center no-repeat;
      background-size: 1em 1em;

      transition: transform 0.5s;
    }

    &.active::after {
      transform: rotate(180deg);
      transition: transform 0.5s;
    }
  }
}

.flex-center {
  align-items: center;
  display: flex;
  justify-content: center;
}

.full-height {
  min-height: calc(100vh - #{$header-height});
}

.fancy-info {
  font-style: italic;
  opacity: .8;
}


.large {
  font-size: 24px;
}

#main form {
  input, textarea {
    &.no-label {
      font-size: 10pt;
      font-weight: 500;
      padding: 3px 8px;
      margin: 4px 0;
    }
  }
}

/*
 * Icons Extensions
 */
.fa.st-dashboard {
  background-image: url('/img/icons/suitcase.svg');
}

/*
 * OVERLAY
 */
.overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  min-height: 100vh;
  z-index: 1000;
  padding: 10vh 25vw;

  &.active {
    display: block;

    -webkit-filter: none !important;
    -ms-filter: none !important;
    filter: none !important;
  }
}

.blur.active {
  -webkit-filter: blur(5px);
  -ms-filter: blur(5px);
  filter: blur(5px);
}

.jura {
  font-family: 'Jura', monospace;
  text-transform: none !important;
}

/*
 * JQUERY UI
 */
.ui-widget {
  &.ui-autocomplete {
    border-radius: 5px;
    border: none;
    background-color: $dropdown-bg;
    padding: 5px 0;

    li {
      color: $dropdown-link-color;
      list-style: none;
      padding-left: 1em;

      &:hover {
        color: $dropdown-active-link-color;
        background-color: $dropdown-active-link-bg;
      }
    }
  }
}

/*
 * OVERRIDES
 */

.app-settings {
  font-weight: 500;
}

.app-settings .panel-body .switches {
  vertical-align: middle;
}

.panel-dark {
  .dataTables_wrapper {
    .dataTables_info {
      display: none;
    }
  }
}

.dataTables_wrapper {

  table {
    margin-top: 0.5em;
  }

  tbody {

    a {
      text-decoration: none;
      color: $text-color;

      &:hover {
        color: $brand-primary;
      }
    }
  }

  .dataTables_paginate {
    text-align: center;

    a.paginate_button {
      $icon-size: 0.6em;

      color: $navbar-default-bg;
      text-decoration: none;
      padding: 0 5px;
      cursor: pointer;

      &:hover {
        color: $navbar-active-link;
      }

      &.previous,
      &.next {
        width: 2em;
        height: 1em;
        vertical-align: middle;
        display: inline-block;
        content: '';
      }

      &.previous {
        background: url('/img/icons/arrow_sm_w_left.svg') no-repeat center center;
        background-size: $icon-size auto;
      }

      &.current {
        border-radius: 3px;
        background-color: $body-bg;
        color: $main-bg;
        vertical-align: middle;
        font-weight: bold;
      }

      &.next {
        background: url('/img/icons/arrow_sm_w_right.svg') no-repeat center center;
        background-size: $icon-size auto;
      }
    }
  }

  .dataTables_length {
    display: inline-block;

    .dataTables_select select {
      color: $text-color;

      width: auto!important;
      min-height: auto!important;

      background: url('/img/icons/arrow_sm_w_down.svg') no-repeat right 0.5em center transparent!important;
      background-size: 0.7em auto!important;

      border-radius: 5px;
      border: 1px solid $text-color;

      padding: 2px 1.3em 2px 2px!important;
      margin: 0 4px!important;

      -webkit-appearance: none;
      -moz-appearance: none;

      option {
        min-height: auto !important;
      }
    }

    select::-ms-expand {
      display: none;
    }
  }

  .dataTables_filter {
    line-height: 1.6;
    vertical-align: middle;
    display: inline-block;
    float: right;

    input {
      margin-left: 1em;
      padding: 0 0.5em;
      background-color: rgba(0, 0, 0, 0.2);
      border-radius: 3px;
      width: auto !important;

      &:focus {
      }
    }
  }
}

/*
 * SNAPTABLES UI
 */

.snap-table {

  .snap-table-controls {
    text-align: center;
  }

  .snap-table-down, .snap-table-up {
    display: inline-block;
    width: 20px;
    height: 20px;
    background: url(/img/icons/arrow_ol_w.svg) center center no-repeat;
    background-size: auto 8px;

    &.disabled {
      opacity: 0.3;

      transition: opacity 100ms;
    }
  }

  .snap-table-up {
    transform: rotate(180deg);
  }
}