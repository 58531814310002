
// Body
$navi-bg: #f4f4f4;
$main-bg: #07273d;
$body-bg: #f4f4f4;
$highlight-color: #0d3551;

// Borders
$laravel-border-color: darken($body-bg, 10%);
$navbar-default-border: #cccccc;
//$list-group-border: $laravel-border-color;

// Brands
$brand-primary: #00a8b5;
$brand-info: #8eb4cb;
$brand-success: #2ab27b;
$brand-warning: #cbb956;
$brand-danger: #bf5329;

// Typography
$font-family-sans-serif: "Raleway", sans-serif;
$font-size-base: 14px;
$line-height-base: 1.6;
$text-color: #fff;
$panel-text-color: #00a8b5;
$dropdown-link-color: #777;
$dropdown-active-link-color: #e97c28;
$dropdown-active-link-bg: #e8e7e7;
$dropdown-bg: #f4f4f4;

// Navbar
$navbar-default-bg: #f4f4f4;
$navbar-selected-bg: #e8e7e7;
$navbar-border-color: #dcdcdc;
$navbar-text-color: #00a8b5;
$navbar-active-link: #e97c28;
$navbar-third-level-bg: #bebebe;
$header-height: 120px;
$navbar-width: 320px;


// Buttons
$btn-default-color: $text-color;

// Inputs
//$input-border: lighten($text-color, 40%);
//$input-border-focus: lighten($brand-primary, 25%);
//$input-color-placeholder: lighten($text-color, 30%);

// Panels
$panel-default-heading-bg: #00a8b5;
$panel-default-bg: rgba(255, 255, 255, 0.7);

// Calendars
$calendar-bg: #f4f4f4;
$calendar-border: none;
$calendar-text: black;
$calendar-text-active: white;
$calendar-dow: black;
$calendar-arrow: #00a8b5;
$calendar-arrow-hover: #e97c28;
$calendar-square-hover: #00a8b5;
$calendar-square-active: #e97c28;
$calendar-square-invalid: #80d3da;
$calendar-box-shadow: rgba(0,0,0,.3);
$calendar-controls-color: #0d3551;