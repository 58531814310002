@import 'variables';

#navigation {
  margin-left: -320px;
  position: absolute;
  left: 0;
  top: $header-height;
  z-index: 1000;

  transition: margin 300ms;
}

#navigation.active {
  margin-left: 0;

  transition: margin 300ms;
}

.fade-in-xs {
  opacity: 1.0;

  -webkit-transition: opacity 300ms;
  -moz-transition: opacity 300ms;
  -ms-transition: opacity 300ms;
  -o-transition: opacity 300ms;
  transition: opacity 300ms;
}

/* Applied at [xs] */
@media (max-width: 767px) {
  #main {
    width: 100%;
  }

  .fixed-320-sm-up {
    width: 100%;
  }
}

/* Applied at [sm - lg] */
@media (min-width: 768px) {
  #navigation {
    margin-left: 0;
    position: inherit;
  }

  .fixed-320-sm-up {
    width: 320px;
  }

  .fade-in-xs {
    opacity: 0.0;

    -webkit-transition: opacity 300ms;
    -moz-transition: opacity 300ms;
    -ms-transition: opacity 300ms;
    -o-transition: opacity 300ms;
    transition: opacity 300ms;
  }
}

/* Applied at [xs - sm] */
@media (max-width: 991px) {
  .remove-grouping-md-down {
    min-height: 0!important;
  }
}

/* Applied at [xs - md] */
@media (max-width: 1199px) {
  #scan-image {
    width: 100%!important;
    height: auto!important;
  }

  .remove-grouping-lg-down {
    min-height: 0!important;
  }
}
