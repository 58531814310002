@font-face {
    font-family: 'Jura';
    src: local('Jura SemiBold'), local('Jura-SemiBold'),
    url('/assets/fonts/Jura-SemiBold.woff2') format('woff2'),
    url('/assets/fonts/Jura-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Jura';
    src: local('Jura Regular'), local('Jura-Regular'),
    url('/assets/fonts/Jura-Regular.woff2') format('woff2'),
    url('/assets/fonts/Jura-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Jura';
    src: local('Jura Bold'), local('Jura-Bold'),
    url('/assets/fonts/Jura-Bold.woff2') format('woff2'),
    url('/assets/fonts/Jura-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Raleway';
    src: local('Raleway Medium'), local('Raleway-Medium'),
        url('/assets/fonts/hinted-Raleway-Medium.woff2') format('woff2'),
        url('/assets/fonts/hinted-Raleway-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Raleway';
    src: local('Raleway Light'), local('Raleway-Light'),
        url('/assets/fonts/hinted-Raleway-Light.woff2') format('woff2'),
        url('/assets/fonts/hinted-Raleway-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Raleway';
    src: local('Raleway SemiBold'), local('Raleway-SemiBold'),
        url('/assets/fonts/hinted-Raleway-SemiBold.woff2') format('woff2'),
        url('/assets/fonts/hinted-Raleway-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Raleway';
    src: local('Raleway Bold'), local('Raleway-Bold'),
        url('/assets/fonts/hinted-Raleway-Bold.woff2') format('woff2'),
        url('/assets/fonts/hinted-Raleway-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

